import React, { useState } from 'react';

import AccountTypeWrapper from './AccountTypeWrapper';
import { SmallText, SubText } from './ThirdStep.style';
import { BackBtn, HistoryButtons } from './SecondStep.style';
import { StepWrapper } from './FirstStep.style';
import Button from '../Shared/Button/Button';
import { MainTitle } from '../SigninContent/style';
import { IFormikErrors } from '../../util/types';
import { ISignUp } from '@pickles/shared/utils/types';
import { AccountType } from '@pickles/shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface IThirdStep extends IFormikErrors<ISignUp> {
  nextStepHandler: () => void;
  previousStepHandler: () => void;
  setFieldValue: (field: 'accountType', value: AccountType) => void;
}

const ThirdStep = ({ nextStepHandler, previousStepHandler, setFieldValue, errors }: IThirdStep) => {
  const [selectedField, setSelectedField] = useState<AccountType | ''>('');
  const { t } = useTranslation();

  const accountTypeHandler = (type: AccountType) => {
    setSelectedField(type);
    setFieldValue('accountType', type);
  };

  return (
    <StepWrapper>
      <MainTitle
        mobileTextAlign={'left'}
        style={{
          marginBottom: '32px',
        }}
      >
        {t('labels:account_type_header')}
      </MainTitle>
      <SubText mobileTextAlign={'left'}>{t('labels:account_type_titel')}</SubText>
      <SmallText mobileTextAlign={'left'}>{t('labels:account_type_desc')}</SmallText>
      <div>
        <AccountTypeWrapper
          accountType={AccountType.Dealer}
          accountTypeHandler={accountTypeHandler}
          activeType={selectedField === AccountType.Dealer}
          isLastElement={false}
          subText={t('labels:dealer_broker_info')}
          text={t('labels:dealer')}
          key="dealer"
          testKey="dealer"
        />
        <AccountTypeWrapper
          accountType={AccountType.Broker}
          accountTypeHandler={accountTypeHandler}
          activeType={selectedField === AccountType.Broker}
          isLastElement={false}
          subText={t('labels:dealer_broker_info')}
          text={t('labels:broker')}
          key="broker"
          testKey="broker"
        />
        <AccountTypeWrapper
          accountType={AccountType.Public}
          accountTypeHandler={accountTypeHandler}
          activeType={selectedField === AccountType.Public}
          isLastElement
          subText={t('labels:public_info')}
          text={t('labels:public')}
          key="public"
          testKey="public"
        />
      </div>
      <HistoryButtons>
        <Button
          hasArrow
          text={t('buttons:next')}
          height="52"
          marginBottom="20"
          id="next"
          type="button"
          onBtnClick={nextStepHandler}
          disabled={!selectedField}
        />
        <BackBtn id="back" onClick={previousStepHandler}>
          {t('buttons:back')}
        </BackBtn>
      </HistoryButtons>
    </StepWrapper>
  );
};

export default ThirdStep;
