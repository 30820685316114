import styled, { CSSProperties } from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

interface ISmalltext {
  mobileTextAlign?: CSSProperties['textAlign'];
}
export const SmallText = styled.div<ISmalltext>`
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  width: 329px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  line-height: 20px;
  @media (max-width: ${sizes.S}) {
    margin: ${({ mobileTextAlign = 'center' }) => (mobileTextAlign === 'center' ? '0 auto' : 0)};
    margin-bottom: 24px;
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 28px;
  background: ${COLORS.zircon_light_blue};
  border-radius: 12px;
  padding-top: 31px;
  padding-right: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  margin-left: 16px;
`;

export const CheckBoxText = styled.div`
  font-family: BasierCircle-Medium;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.rhino_black};
  font-weight: 400;
`;

export const RegularTextWeight = styled.span`
  font-family: BasierCircle;
`;
