import React from 'react';
import Image from 'next/image';
import {
  AccountTypeContentWrapper,
  AccountTypeSubTextWrapper,
  AccountTypeTextWrapper,
  IconTypeWrapper,
  IconWrapper,
  TypeWrapper,
} from './ThirdStep.style';
import { AccountType } from '@pickles/shared/utils/constants';
import { publicIcon, dealerIcon, brokerIcon } from '../Shared/Icons';

interface IAccountTypeWrapper {
  accountType: AccountType;
  accountTypeHandler: (text: AccountType) => void;
  isLastElement: boolean;
  activeType: boolean;
  text: string;
  subText: string;
  testKey: string;
}

const AccountTypeWrapper = ({
  text,
  accountType,
  isLastElement,
  activeType,
  subText,
  testKey,
  accountTypeHandler,
}: IAccountTypeWrapper) => {
  const setIcon = (type: AccountType) => {
    switch (type) {
      case 'DEALER': {
        return dealerIcon;
      }
      case 'BROKER': {
        return brokerIcon;
      }
      case 'PUBLIC': {
        return publicIcon;
      }
    }
  };

  return (
    <TypeWrapper
      id={testKey}
      isLastElement={isLastElement}
      activeType={activeType}
      onClick={() => accountTypeHandler(accountType)}
    >
      <IconTypeWrapper>
        <IconWrapper>
          <Image src={setIcon(accountType)} alt={accountType} />
        </IconWrapper>
      </IconTypeWrapper>
      <AccountTypeContentWrapper>
        <AccountTypeTextWrapper>{text}</AccountTypeTextWrapper>
        <AccountTypeSubTextWrapper>{subText}</AccountTypeSubTextWrapper>
      </AccountTypeContentWrapper>
    </TypeWrapper>
  );
};

export default AccountTypeWrapper;
