import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import colors from '@pickles/shared/utils/colors';

export const ImageWrapper = styled.div`
  margin-bottom: 40px;
  text-align: center;
`;

export const SubText = styled.div`
  text-align: center;
  font-family: BasierCircle-Medium;
  font-size: 17px;
  color: ${COLORS.san_juan_grey};
  margin-bottom: 32px;
  margin-top: 20px;
`;

export const SmallText = styled.div`
  text-align: center;
  font-family: BasierCircle;
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;
  color: ${COLORS.san_juan_grey};
  margin-bottom: 32px;
`;

export const LinkButton = styled.div`
  width: 200px;
  height: 52px;
  margin: 0 auto;
  color: white;
  background: ${COLORS.blumine_main_blue};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: BasierCircle-Semibold;
  font-size: 17px;
  cursor: pointer;
`;

export const LoaderHolder = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GoBackButton = styled.button`
  background: ${colors.blumine_main_blue};
  border-radius: 8px;
  padding: 12px 28px 14px 28px;
  font-size: 17px;
  font-weight: 600;
  color: ${colors.white};
  border: none;
  width: 200px;
  height: 52px;
  line-height: 26px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
