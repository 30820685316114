import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';

export const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
`;

export const CheckBox = styled.div`
  display: grid;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  font-size: 15px;
  text-align: center;
  align-items: center;
  background-color: ${colors.white};
  transition: 0.4s;
  border: 1px solid #d6dadf;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transition: 0.4s;
  }
`;

export const CheckBoxWrapper = styled.label`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 8px;
  vertical-align: middle;
  height: 20px;
  ${CheckBoxInput}:checked + ${CheckBox} {
    border: none;
  }
`;

export const Icon = styled.img`
  width: 20pxp;
  height: 20px;
`;
