import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

interface IContainerUploadAvatar {
  hasImage: boolean;
}

interface iAvatarImage {
  hasImage: boolean;
}

export const ContainerUploadAvatar = styled.div<IContainerUploadAvatar>`
  position: relative;
  // cursor: pointer;
  width: 128px;
  height: 128px;
  box-sizing: border-box;
  border: ${({ hasImage }) => (hasImage ? 'none' : `1.3px dashed ${COLORS.mischka}`)};
  border-radius: 18px;
  margin: 0 auto;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  // input {
  //   position: absolute;
  //   left: 0;
  //   opacity: 0;
  //   width: 148px;
  //   height: 158px;
  //   cursor: pointer;
  // }
`;

export const AvatarImage = styled.img<iAvatarImage>`
  width: ${({ hasImage }) => (hasImage ? 'inherit' : '')};
  height: ${({ hasImage }) => (hasImage ? 'inherit' : '')};
  border: 'none';
  overflow: 'hidden';
  border-radius: '18px';
`;

export const BackBtn = styled.div`
  text-align: center;
  color: ${COLORS.blumine_main_blue};
  font-family: BasierCircle-Semibold;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  padding: 17px 20px;
`;

export const UploadProfilePhotoText = styled.div`
  text-align: center;
  margin: 0 auto;
  font-family: BasierCircle-Medium;
  color: ${COLORS.blumine_main_blue};
  font-size: 15px;
  cursor: pointer;
  width: 155px;
  margin-bottom: 24px;
`;

export const UploadImgInput = styled.input`
  display: none;
`;

export const UploadIdCardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  border: 1.3px dashed ${COLORS.mischka};
  margin-bottom: 30px;
  border-radius: 8px;
  display: flex;
  padding-right: 24px;
  padding-left: 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const UploadIdCardTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: ${COLORS.blumine_main_blue};
`;

export const PlusText = styled.div`
  margin-right: 12px;
`;

export const UploadedIdCardTextWrapper = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  color: ${COLORS.bali_hai};
  line-height: 21px;
`;

export const UploadedIdCardTextSpan = styled.div`
  font-family: BasierCircle-Medium;
  color: ${COLORS.blumine_main_blue};
`;

export const HistoryButtons = styled.div`
  @media (max-width: ${sizes.S}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start;
    > button {
      margin-bottom: 0;
      max-width: 150px;
    }
  }
`;

export const WrappedText = styled.div`
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 140%;
`;

export const IdCardError = styled.div`
  margin-top: -25px;
  margin-bottom: 25px;
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.cerise_red};
`;
