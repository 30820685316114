/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { signUpSchema } from './signup.schema';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import FifthStep from './FifthStep';
import { ISignUp } from '@pickles/shared/utils/types';
import { userActions } from '@pickles/shared/redux/actions';
import withSigninSignupComposer from '../../hocs/withSigninSignupComposer';

interface ISignUpContent {
  step: number;
  setStep: (num: number) => void;
  setIsSignup: (bool: boolean) => void;
}

const initialValues: ISignUp = {
  email: '',
  password: '',
  repeatedPassword: '',
  fullName: '',
  phoneNumber: '',
  passport: '',
  avatarToUpload: null,
  idCardToUpload: null,
  accountType: '',
  usedCarAuction: false,
  usedMotorAuction: false,
  salvageCarAuction: false,
  industrialAuctionList: false,
  agreeToTermsAndConditions: false,
};

const initialValidatedSteps = {
  0: false,
  1: false,
  2: false,
  3: false,
};

const SignUpContent: FC<ISignUpContent> = ({ step, setStep, setIsSignup }) => {
  const dispatch = useDispatch();

  const [validatedSteps, setValidatedSteps] = useState(initialValidatedSteps);

  const useSignUpCallback = (dispatch: any) =>
    useCallback(
      ({
        email,
        password,
        passport,
        fullName,
        accountType,
        phoneNumber,
        industrialAuctionList,
        salvageCarAuction,
        usedCarAuction,
        usedMotorAuction,
        idCardToUpload,
        avatarToUpload,
      }: ISignUp) =>
        dispatch(
          userActions.registerUser(
            {
              email,
              govId: passport,
              username: email,
              lastName: fullName,
              // username: fullName.split(' ')[0],
              // lastName: fullName.split(' ')[1],
              password,
              buyerType: accountType,
              phone: phoneNumber,
            },
            {
              INDUSTRIAL_LIST: industrialAuctionList,
              WKLY_SALVAGE_LIST: salvageCarAuction,
              WKLY_USEDCAR_LIST: usedCarAuction,
              USEDMOTOR_LIST: usedMotorAuction,
            },
            idCardToUpload,
            avatarToUpload,
          ),
        ),
      [dispatch],
    );

  const signUpHandler = useSignUpCallback(dispatch);

  const previousStepHandler = useCallback(() => {
    if (step === 0) {
      return;
    }
    setStep(step - 1);
  }, [step]);

  const {
    values: {
      email,
      password,
      repeatedPassword,
      fullName,
      phoneNumber,
      passport,
      avatarToUpload,
      idCardToUpload,
      accountType,
      usedCarAuction,
      usedMotorAuction,
      salvageCarAuction,
      industrialAuctionList,
      agreeToTermsAndConditions,
    },
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    errors,
    resetForm,
    validateForm,
  } = useFormik<ISignUp>({
    initialValues,
    onSubmit: (values) => {
      signUpHandler(values);
    },
    validationSchema: signUpSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  useEffect(() => {
    setIsSignup(true);
  }, []);

  const clearFormikValues = () => {
    setValidatedSteps(initialValidatedSteps);
    resetForm();
    validateForm(initialValues);
  };

  const firstStepHasError = useMemo(
    () => !!errors.email || !!errors.password || !!errors.repeatedPassword,
    [errors],
  );

  const secondStepHasError = useMemo(
    () =>
      !!errors.fullName ||
      !!errors.phoneNumber ||
      !!errors.passport ||
      !!errors.idCardToUpload,
    [errors],
  );

  const thirdStepHasError = useMemo(() => !!errors.accountType, [errors]);

  const forthStepHasError = useMemo(
    () => !!errors.industrialAuctionList || !!errors.usedCarAuction || !!errors.usedMotorAuction  || !!errors.salvageCarAuction,
    [errors],
  );

  const nextStepHandler = useCallback(() => {
    setValidatedSteps((prev) => ({ ...prev, [step]: true }));

    if (
      (step === 0 && firstStepHasError) ||
      (step === 1 && secondStepHasError) ||
      (step === 2 && thirdStepHasError) ||
      (step === 3 && forthStepHasError) ||
      step === 4
    ) {
      return;
    }

    setStep(step + 1);
  }, [step, firstStepHasError, secondStepHasError, thirdStepHasError, forthStepHasError]);

  const renderFormContent = () => {
    switch (step) {
      default:
      case 0: {
        return (
          <FirstStep
            email={email}
            password={password}
            repeatedPassword={repeatedPassword}
            errors={validatedSteps['0'] ? errors : {}}
            nextStepHandler={nextStepHandler}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );
      }
      case 1: {
        return (
          <SecondStep
            errors={validatedSteps['1'] ? errors : {}}
            fullName={fullName}
            passport={passport}
            phoneNumber={phoneNumber}
            handleChange={handleChange}
            nextStepHandler={nextStepHandler}
            previousStepHandler={previousStepHandler}
            avatarToUpload={avatarToUpload}
            idCardToUpload={idCardToUpload}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
          />
        );
      }
      case 2: {
        return (
          <ThirdStep
            errors={validatedSteps['2'] ? errors : {}}
            nextStepHandler={nextStepHandler}
            previousStepHandler={previousStepHandler}
            setFieldValue={setFieldValue}
          />
        );
      }
      case 3: {
        return (
          <FourthStep
            handleSubmit={handleSubmit}
            nextStepHandler={nextStepHandler}
            previousStepHandler={previousStepHandler}
            handleChange={handleChange}
            usedCarAuction={usedCarAuction}
            usedMotorAuction={usedMotorAuction}
            salvageCarAuction={salvageCarAuction}
            industrialAuctionList={industrialAuctionList}
            agreeToTermsAndConditions={agreeToTermsAndConditions}
          />
        );
      }
      case 4: {
        return <FifthStep setStep={setStep} resetForm={clearFormikValues} />;
      }
    }
  };

  return <div>{renderFormContent()}</div>;
};

export default withSigninSignupComposer(SignUpContent);
