import styled, { CSSProperties } from 'styled-components';
import sizes from '../../util/sizes';

interface ITypeWrapper {
  isLastElement: boolean;
  activeType: boolean;
}
interface ISubText {
  mobileTextAlign?: CSSProperties['textAlign'];
}
export const SubText = styled.div<ISubText>`
  font-size: 20px;
  font-family: BasierCircle-SemiBold;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: center;
  @media (max-width: ${sizes.S}) {
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;

interface ISmalltext {
  mobileTextAlign?: CSSProperties['textAlign'];
}
export const SmallText = styled.div<ISmalltext>`
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 26px;
  @media (max-width: ${sizes.S}) {
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;

export const TypeWrapper = styled.div<ITypeWrapper>`
  width: 370px;
  height: 84px;
  border: ${({ activeType }) => (activeType ? '2px solid #1C4D8E' : '1px solid #D6DADF')};
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: ${({ isLastElement }) => (isLastElement ? '28px' : '12px')};
  display: flex;
  padding-left: 17px;
  background: ${({ activeType }) => (activeType ? '#F7FCFF' : 'none')};
`;

export const IconTypeWrapper = styled.div`
  position: relative;
  width: 24px;
`;

export const IconTypeImg = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const AccountTypeContentWrapper = styled.div`
  margin-left: 17px;
  margin-top: 21px;
`;

export const AccountTypeTextWrapper = styled.div`
  font-family: BasierCircle;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 2px;
`;

export const AccountTypeSubTextWrapper = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
`;
