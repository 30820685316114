import Image from 'next/image';
import React, { ChangeEvent, RefObject } from 'react';
import { CheckBox, CheckBoxInput, CheckBoxWrapper, Icon } from './style';
import checkboxIcon from '../../../public/icons/checkboxIcon.svg';

interface ICheckbox {
  id: string;
  name: string;
  isChecked: boolean;
  handleChecked: (event: ChangeEvent<any>) => void;
  inputRef?: RefObject<HTMLInputElement>;
}

const Checkbox = ({ id, name, isChecked, handleChecked, inputRef }: ICheckbox) => {
  return (
    <CheckBoxWrapper>
      <CheckBoxInput
        ref={inputRef}
        id={id}
        name={name}
        checked={isChecked}
        onChange={handleChecked}
      />
      <CheckBox>{isChecked && <Image src={checkboxIcon} alt="checkbox icon" />}</CheckBox>
    </CheckBoxWrapper>
  );
};

export default Checkbox;
