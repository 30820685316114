import React, { ChangeEvent, FC } from 'react';
import Link from 'next/link';

import { StepWrapper } from './FirstStep.style';
import {
  DontHaveAccText,
  DontHaveAccTextWrapper,
  MainTitle,
  OrBlockLine,
  OrText,
  SignUpText,
} from '../SigninContent/style';
import { IFormikErrors } from '../../util/types';
import Button from '../Shared/Button/Button';
import Input from '../Shared/Input/Input';
import { ISignUp } from '@pickles/shared/utils/types';
import { useTranslation } from 'react-i18next';

interface IFirstStep extends IFormikErrors<ISignUp> {
  email: string;
  password: string;
  repeatedPassword: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  nextStepHandler: () => void;
}

const FirstStep: FC<IFirstStep> = ({
  email,
  password,
  repeatedPassword,
  errors,
  handleChange,
  nextStepHandler,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <StepWrapper>
      <MainTitle
        mobileTextAlign={'left'}
        style={{
          marginBottom: '38px',
        }}
      >
        {t('labels:create_account')}
      </MainTitle>
      <div>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          borderRadius={8}
          title={t('labels:email_address')}
          placeholder={t('fields:new_email_address')}
          type="email"
          width="370px"
          iconType="email"
          value={email}
          id="email"
          marginBottom={30}
          name="email"
          error={errors.email}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          borderRadius={8}
          title={t('labels:password')}
          placeholder={t('fields:password')}
          type="password"
          width="370px"
          iconType="password"
          value={password}
          id="password"
          marginBottom={5}
          name="password"
          isPassword
          error={errors.password}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          borderRadius={8}
          placeholder={t('fields:confirm_password')}
          type="password"
          width="370px"
          iconType="password"
          value={repeatedPassword}
          id="repeatedPassword"
          marginBottom={20}
          name="repeatedPassword"
          isPassword
          error={errors.repeatedPassword}
        />
      </div>

      <Button
        hasArrow={true}
        text={t('buttons:next')}
        height="52"
        marginBottom="28"
        id="next"
        type="button"
        onBtnClick={nextStepHandler}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '28px',
        }}
      >
        <OrBlockLine />
        <OrText>{t('labels:or')}</OrText>
        <OrBlockLine />
      </div>
      <DontHaveAccTextWrapper>
        <DontHaveAccText>{t('labels:have_account')}</DontHaveAccText>
        <Link href="/login" passHref>
          <SignUpText id="sign_in">{t('buttons:sign_in')}</SignUpText>
        </Link>
      </DontHaveAccTextWrapper>
    </StepWrapper>
  );
};

export default FirstStep;
