import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MainTitle } from '../SigninContent/style';
import { StepWrapper } from './FirstStep.style';
import { webActions } from '@pickles/shared/redux/actions';
import { useTranslation } from 'react-i18next';
import { GoBackButton, ImageWrapper, SmallText, SubText } from './FifthStep.style';
import Image from 'next/image';
import { errorIcon } from '../Shared/Icons';
import { WebHooks } from '../../app/redux/hooks';

interface IRegisterError {
  error: string;
  setStep: (step: number) => void;
  resetForm: () => void;
}

const RegisterError: FC<IRegisterError> = ({ setStep, resetForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const error = WebHooks.useRegisterErrorMsg();

  const firstStepHandler = useCallback(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(webActions.clearRegisterError());
      resetForm();
    };
  }, [dispatch]);

  return (
    <StepWrapper>
      <ImageWrapper>
        <Image src={errorIcon} alt="checked icon" width="94px" height="94px" />
      </ImageWrapper>
      <MainTitle id="error" lineHeight={40}>
        {t('errors:error_title')}
      </MainTitle>
      {/* <div onClick={firstStepHandler} style={{
        marginTop: '15px',
        cursor: 'pointer'
      }}>On sign up page</div> */}
      <SubText id="error_title">{error?.title}</SubText>
      <SmallText id="error_message">{error?.message}</SmallText>
      <GoBackButton>
        <div id="go_back" onClick={firstStepHandler}> {t('buttons:go_back')} </div>
      </GoBackButton>
    </StepWrapper>
  );
};

export default RegisterError;
