import styled from 'styled-components';
import sizes from '../../util/sizes';

export const StepWrapper = styled.div`
  width: 370px;
  margin: 0 auto;
  @media (max-width: ${sizes.S}) {
    max-width: calc(100% - 40px);
  }
  @media (max-width: ${sizes.XS}) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
