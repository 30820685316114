import { AccountType } from '@pickles/shared/utils/constants';
import { boolean, object, string, ref, addMethod, mixed } from 'yup';
import { i18n } from '../../pages/_app';

import { fullNameRegExp, phoneRegExp } from '../../util';

const requiredString = string().required();
const notRequiredBoolean = boolean().notRequired();

addMethod(string, 'fullNameCustom', function(errorMessage) {
  // @ts-ignore
  return this.test('fullName', errorMessage, function(value) {
    if (value?.length) {
      const wordsArr = this.parent.fullName.trim().split(' ');
      if (wordsArr.length === 1) {
        this.createError({ message: errorMessage });
      }
      return this.resolve(true);
    }
  });
});

export const signUpSchema = object({
  // First step
  email: string()
    .email()
    .required(),
  password: requiredString.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/,
    i18n.t('labels:password_rules'),
  ),
  repeatedPassword: requiredString
    .label('confirm password')
    .oneOf([ref('password'), null], 'Passwords must match'),
  // Second step
  // @ts-ignore
  fullName: requiredString
    .label('full name')
    .matches(fullNameRegExp, 'Enter full name')
    .fullNameCustom('Name should be longer than 1 word'),
  phoneNumber: requiredString
    .label('phone number')
    .matches(phoneRegExp, 'Phone number is not valid'),
  passport: requiredString.label('IC number or Passport').min(4),
  avatarToUpload: mixed().nullable(),
  idCardToUpload: mixed().required().label('IC or Passport image'),
  // Third step
  accountType: requiredString.oneOf([AccountType.Broker, AccountType.Dealer, AccountType.Public]),
  // Fourth step
  usedCarAuction: notRequiredBoolean,
  usedMotorAuction: notRequiredBoolean,
  salvageCarAuction: notRequiredBoolean,
  industrialAuctionList: notRequiredBoolean,
});
