import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Image from 'next/image';


import { RootState } from '@pickles/shared/redux';
import Loading from '../Shared/Loading';
import { checkedIcon } from '../Shared/Icons';
import { LoaderHooks } from '../../app/redux/hooks';
import RegisterError from './RegisterError';

import { ImageWrapper, LinkButton, LoaderHolder, SmallText, SubText } from './FifthStep.style';
import { MainTitle } from '../SigninContent/style';
import { StepWrapper } from './FirstStep.style';

interface IFifthStep {
  setStep: (step: number) => void;
  resetForm: () => void;
}

const FifthStep: FC<IFifthStep> = ({ setStep, resetForm }) => {
  const isLoading = LoaderHooks .useLoadingIndicator('register');
  const { t } = useTranslation();

  const registerErrorMessage = useSelector<RootState, string | null>(
    (state) => state.web.registerError,
  );

  if (isLoading) {
    return (
      <LoaderHolder>
        <Loading id='signup_loader' />
      </LoaderHolder>
    );
  }

  if (registerErrorMessage) {
    return <RegisterError error={registerErrorMessage} setStep={setStep} resetForm={resetForm} />;
  }

  return (
    <StepWrapper>
      <ImageWrapper>
        <Image src={checkedIcon} alt="checked icon" width="94px" height="94px" />
      </ImageWrapper>
      <MainTitle id='register_complete'>{t('labels:register_complete')}</MainTitle>
      <SubText id='account_pending_title'>{t('infos:account_pending_title_new')}</SubText>
      <SmallText id='account_pending_info'>{t('infos:account_pending')}</SmallText>
      <Link href={'/'} passHref>
        <LinkButton id='get_started'>{t('infos:get_started')}</LinkButton>
      </Link>
    </StepWrapper>
  );
};

export default FifthStep;
