import React, { ChangeEvent, useRef, MutableRefObject, FC, useState } from 'react';

import {
  AvatarImage,
  BackBtn,
  ContainerUploadAvatar,
  HistoryButtons,
  IdCardError,
  PlusText,
  UploadedIdCardTextSpan,
  UploadedIdCardTextWrapper,
  UploadIdCardTextWrapper,
  UploadIdCardWrapper,
  UploadImgInput,
  UploadProfilePhotoText,
  WrappedText,
} from './SecondStep.style';
import { StepWrapper } from './FirstStep.style';
import { MainTitle } from '../SigninContent/style';
import Button from '../Shared/Button/Button';
import Input from '../Shared/Input/Input';
import { IFormikErrors } from '../../util/types';
import { ISignUp } from '@pickles/shared/utils/types';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { idCardCheckIcon, idCardIcon } from '../Shared/Icons';

interface ISecondStep extends IFormikErrors<ISignUp> {
  phoneNumber: string;
  passport: string;
  fullName: string;
  avatarToUpload: Blob | null;
  idCardToUpload: Blob | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  nextStepHandler: () => void;
  previousStepHandler: () => void;
  setFieldValue: (field: string, value: string | ArrayBuffer | null) => void;
}

const SecondStep: FC<ISecondStep> = ({
  fullName,
  phoneNumber,
  passport,
  avatarToUpload,
  idCardToUpload,
  handleChange,
  handleBlur,
  nextStepHandler,
  previousStepHandler,
  setFieldValue,
  errors: {
    fullName: errorFullName,
    phoneNumber: errorPhone,
    passport: errorPassport,
    idCardToUpload: errorIdCardToUpload,
  },
}) => {
  const { t } = useTranslation();
  const idCardRef: MutableRefObject<null | HTMLInputElement> = useRef(null);
  const avatarInput: MutableRefObject<null | HTMLInputElement> = useRef(null);

  const [avatarPreview, setAvatarPreview] = useState('');

  const onUploadImageHandler = (e: any, fieldValue: string) => {
    e.preventDefault();
    let files;
    const res: never[] = [];
    if (e.dataTransfer && e !== res) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = async () => {
      if (reader) {
        const base64 = reader.result;
        const base64Response = await fetch(base64 as string);
        const blob = await base64Response.blob();
        if (fieldValue === 'avatarToUpload') {
          setAvatarPreview(base64 as string);
        }
        setFieldValue(fieldValue, blob);
      }
    };
  };

  return (
    <StepWrapper>
      <MainTitle
        mobileMarginTop={44}
        mobileTextAlign={'left'}
        style={{
          marginBottom: '38px',
        }}
      >
        {t('labels:profile_info')}
      </MainTitle>
      <div>
        <ContainerUploadAvatar
          hasImage={!!avatarToUpload}
          onClick={() => {
            avatarInput?.current?.click();
          }}
        >
          <AvatarImage
            src={avatarToUpload ? avatarPreview : './icons/profileAvatarIcon.svg'}
            alt="Avatar image"
            hasImage={!!avatarToUpload}
          />
          <UploadImgInput
            type="file"
            id="avatarToUpload"
            name="avatarToUpload"
            accept="image/png, image/jpeg"
            onChange={(e: any) => onUploadImageHandler(e, 'avatarToUpload')}
            ref={avatarInput}
          />
        </ContainerUploadAvatar>
        <UploadProfilePhotoText onClick={() => avatarInput?.current?.click()}>
          {avatarToUpload ? `${t('labels:change_img')}` : `${t('labels:upload_avatar')}`}
        </UploadProfilePhotoText>
        <Input
          onChange={handleChange}
          borderRadius={8}
          title={t('labels:personal_details')}
          placeholder="Enter your full name"
          type="text"
          width="370px"
          iconType="fullName"
          value={fullName}
          id="fullName"
          marginBottom={8}
          name="fullName"
          error={errorFullName}
          onBlur={handleBlur}
        />
        <Input
          onChange={handleChange}
          borderRadius={8}
          placeholder={t('fields:phone_number')}
          type="tel"
          width="370px"
          iconType="phone"
          value={phoneNumber}
          id="phoneNumber"
          marginBottom={30}
          name="phoneNumber"
          error={errorPhone}
        />
        <Input
          onChange={handleChange}
          borderRadius={8}
          title={t('labels:personal_id')}
          placeholder={t('fields:passport')}
          type="text"
          width="370px"
          value={passport}
          id="passport"
          marginBottom={8}
          name="passport"
          paddingLeft={22}
          error={errorPassport}
          onBlur={handleBlur}
        />
        <UploadIdCardWrapper
          onClick={() => {
            idCardRef?.current?.click();
          }}
        >
          <UploadImgInput
            type="file"
            accept="image/png, image/jpeg"
            id="idCardToUpload"
            name="idCardToUpload"
            ref={idCardRef}
            onChange={(e: any) => onUploadImageHandler(e, 'idCardToUpload')}
          />
          {!idCardToUpload ? (
            <UploadIdCardTextWrapper>
              <PlusText>+</PlusText>
              <WrappedText>{t('labels:upload_id')}</WrappedText>
            </UploadIdCardTextWrapper>
          ) : (
            <UploadedIdCardTextWrapper>
              {t('labels:uploaded_id')} <br />
              <UploadedIdCardTextSpan>{t('labels:change_img')}</UploadedIdCardTextSpan>
            </UploadedIdCardTextWrapper>
          )}
          <div>
            <Image
              width="25px"
              height="25px"
              src={!idCardToUpload ? idCardIcon : idCardCheckIcon}
              alt=""
            />
          </div>
        </UploadIdCardWrapper>
        {errorIdCardToUpload && <IdCardError>{errorIdCardToUpload}</IdCardError>}
      </div>
      <HistoryButtons>
        <Button
          hasArrow
          text={t('buttons:next')}
          height="52"
          marginBottom="20"
          id="next"
          type="button"
          onBtnClick={nextStepHandler}
        />
        <BackBtn id="back" onClick={previousStepHandler}>
          {t('buttons:back')}
        </BackBtn>
      </HistoryButtons>
    </StepWrapper>
  );
};

export default SecondStep;
